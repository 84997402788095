import env from '@beam-australia/react-env'
import { datadogRum } from '@datadog/browser-rum'
import { initializeDatadogBrowserLogs } from '@knauf-group/ct-shared-nextjs/datadog'

import pkg from '../../package.json'

const service = 'pcat-frontend'

if (env('DATADOG_IS_RUM_ENABLED') === 'true') {
  datadogRum.init({
    applicationId: env('DATADOG_RUM_APPLICATION_ID'),
    clientToken: env('DATADOG_LOGS_CLIENT_TOKEN'),
    site: 'datadoghq.eu',
    service,
    env: env('APP_ENV'),
    version: pkg.version,
    sessionSampleRate: parseInt(env('DATADOG_RUM_SAMPLE_RATE'), 10),
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}

initializeDatadogBrowserLogs({
  service,
  version: pkg.version,
})
